import { Collapse } from 'bootstrap';

const collapseElement = document.querySelector('.navbar .collapse');
const collapse = Collapse.getOrCreateInstance(collapseElement, { toggle: false });

const sections = document.querySelectorAll('section');
const navLinks = document.querySelectorAll('.navbar .nav-link');

function handleScroll() {
    let currentSection = '';
    const scrollPosition = window.scrollY + window.innerHeight;  // Current scroll position including viewport height
    const documentHeight = document.documentElement.scrollHeight; // Total height of the document

    // Check if the user is at the bottom of the page
    if (scrollPosition >= documentHeight - 2) {
        // Set the last navigation link as active
        currentSection = sections[sections.length - 1].getAttribute("id");
    } else {
        // Loop through sections to find the one currently in view
        sections.forEach((section) => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;

            if (window.scrollY >= sectionTop - sectionHeight / 3) {
                currentSection = section.getAttribute("id");
            }
        });
    }

    navLinks.forEach((link) => {
        link.classList.remove("active");
        if (link.getAttribute("href").includes(currentSection)) {
            link.classList.add("active");
        }
    });
}

[...navLinks].forEach((navLink) => {
   navLink.addEventListener('click', (e) => {
       // e.preventDefault();
       [...navLinks].forEach((link) => {
           link.classList.remove('active');
       });

       navLink.classList.add('active');

       collapse.hide();
   })
});

window.addEventListener('scroll', handleScroll);
